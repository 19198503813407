import firebase from "firebase/compat/app"
//import { deleteUser  } from "firebase/auth"

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchUsers() {
            return new Promise(resolve => {
                firebase
                    .firestore()
                    .collection('admin_users')
                    .get()
                    .then(snapshot => {
                        let users = []
                        snapshot.docs.forEach(u => {
                            const user = u.data()
                            user.id = u.id

                            users.push(user)
                        })


                        resolve(users)
                    })
            })
        },
        addUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                firebase.auth().createUserWithEmailAndPassword(userData.username, userData.password).then(() => {
                    firebase.firestore().collection('admin_users').add({username: userData.username}).then(() => {
                        resolve(true)
                    })
                }).catch(err => {
                    reject(err)
                })
            })
        },
        /*editUser(ctx, userData) {
            return new Promise((resolve, reject) => {
                //firebase.auth().up

            })
        },*/
        deleteUser(ctx, data) {
            return new Promise(resolve => {
                const fn = firebase.functions().httpsCallable('deleteAdminUser')
                fn({email: data}).then(users => {
                    resolve(users)
                }).catch(() => {
                    // console.log(err)
                })
            })
        }
    }
}
