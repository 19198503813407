<template>
    <div>
        <user-list-add-new
            :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
            :add-new-user="addNewUser"
            :user-selected="userSelected"
            @refetch-data="refetchData"
        />

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0"
        >

            <div class="m-2">

                <!-- Table Top -->
                <b-row>
                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="12"
                    >
                        <div class="d-flex align-items-center justify-content-end">
                            <!--<b-form-input
                              v-model="searchQuery"
                              class="d-inline-block mr-1"
                              placeholder="Search..."
                            />!-->
                            <b-button
                                variant="primary"
                                @click="addNew"
                            >
                                <span class="text-nowrap">Agregar usuario</span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>

            </div>

            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="fetchUsers"
                responsive
                :fields="tableColumns"
                primary-key="id"
                show-empty
                empty-text="No matching records found"
            >

                <!-- Column: User -->
                <template #cell(user)="data">
                    {{ data.item.username }}
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button-group>
                        <!--                        <b-button variant="primary" @click="editUser(data.item)">
                                                    <feather-icon
                                                        icon="EditIcon"
                                                        size="16"
                                                        class="align-middle"
                                                    />
                                                </b-button>-->
                        <b-button variant="danger" @click="deleteUser(data.item)">
                            <feather-icon
                                icon="Trash2Icon"
                                size="16"
                                class="align-middle"
                            />
                        </b-button>
                    </b-button-group>
                </template>

            </b-table>
        </b-card>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BButton,
    BCol,
    BTable,
    BButtonGroup
} from 'bootstrap-vue'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import userStoreModule from "@/views/admin-users/userStoreModule"
import useUsersList from "@/views/admin-users/useUsersList"
import UserListAddNew from './UserListAddNew.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent"

export default {
    name: "AdminUsersView",
    components: {
        BCard,
        BRow,
        BCol,
        BButton,
        BTable,
        BButtonGroup,

        UserListAddNew
    },
    methods: {
        addNew() {
            this.userSelected = {}
            this.addNewUser = true
            this.isAddNewUserSidebarActive = true
        },
        editUser(user) {
            this.userSelected = user
            this.isAddNewUserSidebarActive = true
            this.addNewUser = false
        },
        deleteUser(user) {

            this.$swal({
                title: '¿Seguro que desea eliminar el usuario?',
                text: "Ya no podrá acceder al sistema",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    store
                        .dispatch('app-user/deleteUser', user.username)
                        .then(response => {
                            if(response){
                                this.refetchData()
                            }
                        })
                        .catch(() => {
                            // console.log(err)
                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'Error fetching users list',
                                    icon: 'AlertTriangleIcon',
                                    variant: 'danger',
                                },
                            })
                        })
                }
            })
        }
    },
    setup() {
        const USER_APP_STORE_MODULE_NAME = 'app-user'

        // Register module
        if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
        })
        const isAddNewUserSidebarActive = ref(false)

        const {
            fetchUsers,
            tableColumns,
            refUserListTable,
            refetchData,
        } = useUsersList()

        const addNewUser = ref(true)
        const userSelected = ref({})

        return {

            // Sidebar
            isAddNewUserSidebarActive,

            fetchUsers,
            tableColumns,
            refUserListTable,
            refetchData,
            addNewUser,
            userSelected
        }
    }
}
</script>
